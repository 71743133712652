var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "q-px-md" },
    [
      _c("br"),
      _c("span", { staticClass: "text-h5" }, [_vm._v("Ricerca Clienti")]),
      _c("br"),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.sta_caricando,
                expression: "sta_caricando",
              },
            ],
            staticClass: "text-center q-pa-md q-gutter-sm absolute-center",
            staticStyle: { "z-index": "1000" },
          },
          [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "col q-mr-sm" },
          [
            _c("q-input", {
              staticClass: "q-mb-sm",
              attrs: { outlined: "", label: "Cognome / Ragione Sociale" },
              model: {
                value: _vm.Cognome,
                callback: function ($$v) {
                  _vm.Cognome = $$v
                },
                expression: "Cognome",
              },
            }),
            _c("q-input", {
              attrs: { outlined: "", label: "Codice Fiscale" },
              model: {
                value: _vm.CodiceFiscale,
                callback: function ($$v) {
                  _vm.CodiceFiscale = $$v
                },
                expression: "CodiceFiscale",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col q-mr-sm" },
          [
            _c("q-input", {
              staticClass: "q-mb-sm",
              attrs: { outlined: "", label: "Nome" },
              model: {
                value: _vm.Nome,
                callback: function ($$v) {
                  _vm.Nome = $$v
                },
                expression: "Nome",
              },
            }),
            _c("q-input", {
              attrs: { outlined: "", label: "Partita IVA" },
              model: {
                value: _vm.PartitaIva,
                callback: function ($$v) {
                  _vm.PartitaIva = $$v
                },
                expression: "PartitaIva",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c("br"),
          _c("QQButton", {
            attrs: {
              label: "CERCA",
              color: "blue-grey",
              icon: "mdi-file-find-outline",
              size: "sm",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onCercaClienti.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c("hr", { staticStyle: { color: "#fff" } }),
      _c("q-table", {
        staticClass: "my-sticky-header-table no-box-shadow q-mt-none",
        attrs: {
          title: "Clicca su un Cliente per visualizzare la scheda",
          data: _vm.righe,
          columns: _vm.colonne,
          filter: _vm.filter,
          dense: "",
          "row-key": "name",
        },
        on: { "row-click": _vm.onSelezionaCliente },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (props) {
              return [
                _c(
                  "q-tr",
                  { attrs: { props: props } },
                  _vm._l(props.cols, function (col) {
                    return _c(
                      "q-th",
                      {
                        key: col.name,
                        staticStyle: {
                          "font-size": "14px",
                          "border-bottom": "1px SOLID #404040",
                          "background-color": "#ffcc99",
                          color: "#000",
                        },
                        attrs: { props: props },
                      },
                      [_c("strong", [_vm._v(_vm._s(col.label))])]
                    )
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }